<div class="container py-5">
    <div class="h3">Seus clientes cadastrados</div>
    <hr>
    <p>Todos os seus clientes dastrados nos testes aparecerão aqui.</p>
    <ul class="list-group">
        <li *ngFor="let usuario of user?.clients" class="list-group-item list-group-item-action">
            <div>
                {{ usuario?.name }}
                <a class="bi bi-person-dash-fill float-end m-1" style="cursor:pointer;" (click)="excluir()"></a>
                <a class="bi bi-pencil-square float-end m-1 link" style="cursor:pointer;" (click)="editar()"></a>                
            </div>
        </li>
    </ul>
</div>