<div class="bg-dark min-vh-100">
    <div class="container pt-md-4">
        <div class="row">
            <div class="col-md-3 mb-md-0 mb-3 vh-100" style=" overflow-y: auto;">
                <div class="h1 text-white mb-3 text-center">
                    ATIVIDADES
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Criar nova Atividade</h5>
                    </div>
                    <div class="card-body">
                        <input type="text" class="form-control mb-2" placeholder="Titulo da Atividade"
                            [(ngModel)]="atividade.titulo" #titulo="ngModel">
                        <textarea class="form-control mb-2" placeholder="Descrição da atividade" rows="3"
                            [(ngModel)]="atividade.descricao" #descricao="ngModel"></textarea>
                        <div class="form-group">
                            <label>Importância {{ importancia.value }} a 10</label>
                            <input type="range" class="w-100 form-control-range" min="0" max="10"
                                [(ngModel)]="atividade.importancia" #importancia="ngModel">
                        </div>
                        <div class="form-group">
                            <label>Urgência {{ urgencia.value }} a 10</label>
                            <input type="range" class="w-100 form-control-range" min="0" max="10"
                                [(ngModel)]="atividade.urgencia" #urgencia="ngModel">
                        </div>

                        <a class="my-2 float-end btn btn-primary" (click)="criarNovaAtividade()">Adicionar Atividade</a>
                    </div>
                </div>

                <div>
                    <div *ngFor="let atividade of atividadesList let i=index" class="card my-2">
                        <div class="card-header">
                            <h5 class="card-title">{{ 'Titulo: ' + atividade.titulo }}</h5>
                        </div>
                        <div class="card-body">
                            <p class="cart-text">{{ 'Urgencia: ' + atividade.urgencia}} <br> {{ 'Importancia: ' +
                                atividade.importancia}}</p>
                            <p class="cart-text">{{ 'Descrição: ' + atividade.descricao }}</p>
                            <div class="float-end">
                                <img (click)="deleteItem(i)" src="../../../assets/excludeIcon.svg" style="width: 10px; cursor: pointer;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 border-start border-white borda-mobile">
                <div class="row">
                    <div class="col-1 d-flex flex-column justify-content-center align-items-center">
                        <img src="../../../assets/s1.png" class="img-importante">
                    </div>
                    <div class="col-11">
                        <label class="fw-bold text-info mb-2">10 - Máximo</label>
                        <div class="row mb-3">
                            <div class="col-6">
                                <div class="card border-info bg-transparent  h-100">
                                    <div class="card-header">
                                        <h5 class="card-title text-info text-center">FAÇA</h5>
                                    </div>
                                    <div class="card-body">
                                        <p class="card-text text-info">Esta atividade é Importante e não urgente.</p>
                                        <div *ngFor="let atividade of getDo()" class="card borda-do my-1 p-1 ps-3">
                                            <div class="row">
                                                <div class="col-10">
                                                    <h5 class="card-title">{{ atividade.item.titulo }}</h5>
                                                </div>
                                                <div class="col-2 px-4">
                                                    <img (click)="deleteItem(atividade.id)"
                                                        src="../../../assets/excludeIcon.svg"
                                                        style="width: 10px; cursor: pointer;">
                                                </div>
                                            </div>
                                            <div class="cart-text">{{ atividade.item.descricao }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card bg-transparent border-purple  h-100">
                                    <div class="card-header">
                                        <h5 class="card-title text-center text-purple">FAÇA AGORA</h5>
                                    </div>
                                    <div class="card-body">
                                        <p class="card-text text-purple">Esta atividade é Importante e Urgente.</p>
                                        <div *ngFor="let atividade of getDoNow()"
                                            class="card borda-do-now my-1 p-1 ps-3">
                                            <div class="row">
                                                <div class="col-10">
                                                    <h5 class="card-title">{{ atividade.item.titulo }}</h5>
                                                </div>
                                                <div class="col-2 px-4">
                                                    <img (click)="deleteItem(atividade.id)"
                                                        src="../../../assets/excludeIcon.svg"
                                                        style="width: 10px; cursor: pointer;">
                                                </div>
                                            </div>
                                            <div class="cart-text">{{ atividade.item.descricao }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <div class="card mb-3 bg-transparent border-secondary h-100">
                                    <div class="card-header">
                                        <h5 class="card-title text-center text-secondary">NÃO FAÇA</h5>
                                    </div>
                                    <div class="card-body">
                                        <p class="card-text text-white">Esta atividade não é Importante nem Urgente.</p>
                                        <div *ngFor="let atividade of getNoToDo()"
                                            class="card borda-not-do my-1 p-1 ps-3">
                                            <div class="row">
                                                <div class="col-10">
                                                    <h5 class="card-title">{{ atividade.item.titulo }}</h5>
                                                </div>
                                                <div class="col-2 px-4">
                                                    <img (click)="deleteItem(atividade.id)"
                                                        src="../../../assets/excludeIcon.svg"
                                                        style="width: 10px; cursor: pointer;">
                                                </div>
                                            </div>
                                            <div class="cart-text">{{ atividade.item.descricao }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card border-danger bg-transparent mb-3 h-100">
                                    <div class="card-header">
                                        <h5 class="card-title text-center text-danger">DELEGUE OU NÃO FAÇA</h5>
                                    </div>
                                    <div class="card-body">
                                        <p class="card-text text-danger">Esta atividade é Urgente mas não é Importante.
                                        </p>
                                        <div *ngFor="let atividade of getDelegate() let i = index"
                                            class="card borda-delegate my-1 p-1 ps-3">

                                            <div class="row">
                                                <div class="col-10">
                                                    <h5 class="card-title">{{ atividade.item.titulo }}</h5>
                                                </div>
                                                <div class="col-2 px-4">
                                                    <img (click)="deleteItem(atividade.id)"
                                                        src="../../../assets/excludeIcon.svg"
                                                        style="width: 10px; cursor: pointer;">
                                                </div>
                                            </div>
                                            <div class="cart-text">{{ atividade.item.descricao }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fw-bold mt-2">
                            <div class="float-start">
                                <label class="text-secondary">0 - Mínimo</label>
                            </div>
                            <div class="float-end">
                                <label class="text-danger">Máximo - 10</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <img src="../../../assets/s2.png" class="img-urgente mx-auto">
                </div>
            </div>
        </div>
    </div>
</div>