<div class="min-vh-100 bg-dark">
    <div class="container py-2 py-md-4 text-white bold-persona">
        <h1 class="bold-title-persona text-center">MINHA PERSONA</h1>
        <div class="row">
            <div class="col-md-6 mb-2">
                <div class="p-3 h-100 bg-orange rounded">
                    <div class="text-center">MEUS VALORES</div>
                    <div class="form-group mt-2">
                        {{ resultado[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-2">
                <div class="p-3 h-100 bg-orange rounded">
                    <div class="text-center">O QUE FAÇO NO DIA A DIA</div>
                    <div class="form-group mt-2">
                        {{ resultado[1] }}
                    </div>
                </div>
            </div>
            <div class="col-md-4 py-md-3 mb-2 mb-md-0">
                <div class="p-3 bg-orange rounded h-50 mb-2">
                    <div class="text-center">MINHAS METAS</div>
                    <div class="form-group mt-2">
                        {{ resultado[2] }}
                    </div>
                </div>
                <div class="p-3 pb-md-5 bg-orange rounded h-50">
                    <div class="text-center">MARCAS QUE USO</div>
                    <div class="form-group mt-2">
                        {{ resultado[3] }}
                    </div>
                </div>
            </div>
            <div class="col-md-4 py-md-3 my-3 my-md-0">
                <div class="p-3 bg-primary rounded h-100">
                    <div class="d-flex justify-content-center">
                        <img [src]="avatar" class="icon-main-persona" alt="">
                    </div>
                    <div>Idade: {{ resultado[4] }} </div>
                    <div>Trabalha em: {{ resultado[5] }}</div>
                    <div>Cargo: {{ resultado[6] }}</div>
                    <div>Educação e personalidade: {{ resultado[7] }}</div>
                </div>
            </div>
            <div class="col-md-4 py-md-3 mb-3 mb-md-0">
                <div class="p-3 bg-orange rounded h-50 mb-2">
                    <div class="text-center">MEU SONHO</div>
                    <div class="form-group mt-2">
                        {{ resultado[8] }}
                    </div>
                </div>
                <div class="p-3 pb-md-5 bg-orange rounded h-50">
                    <div class="text-center">O QUE CONSOMO NO DIA A DIA</div>
                    <div class="form-group mt-2">
                        {{ resultado[9] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-2 mt-md-2">
                <div class="p-3 bg-orange rounded h-100">
                    <div class="text-center">MINHAS DORES</div>
                    <div class="form-group mt-2">
                        {{ resultado[10] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-2 mt-md-2">
                <div class="p-3 bg-orange rounded h-100">
                    <div class="text-center">O QUE MEU NEGÓCIO IMPACTA NA MINHA VIDA</div>
                    <div class="form-group mt-2">
                        {{ resultado[11] }}
                    </div>
                </div>
            </div>
            <div class="col-12 py-3">
                <button class="btn btn-primary float-end" (click)="imprimir()">Imprimir</button>
            </div>
        </div>
    </div>
</div>