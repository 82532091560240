<!--div class="card-section">
  <div class="container text-center py-4 px-md-5">
    <h3 class="card-titulo">INSCRIÇÕES ABERTAS PARA A FORMAÇÃO DE HIPNOSE TRANSFORMACIONAL GRATUITA</h3>
    <div>
      <p class="card-subtitulo">DOMINE SUAS EMOÇÕES COM O CURSO DE HIPNOSE TRANSFORMACIONAL GRATUITO</p>
      <a class="btn-home" href="https://bit.ly/aprenda-hipnose-transformacional">
        SIM! Quero fazer minha inscrição para o curso gratuito!
      </a>
    </div>
  </div>
</div-->
<div class="section-home py-5 header-home">
  <div class="container-center" id="home1">
    <h1 class="title-home">SOCIEDADE BRASILEIRA DE TERAPIA E NEUROCIÊNCIA</h1>
    <img class="logo-home"
      src="https://uploads-ssl.webflow.com/5e2088af647de0b43c9ff21d/5f902cdd8b72cf4b43a50172_sbtn2-p-500.png" />
    <div class="row">
      <div class="col-sm coluna-preencher">
        <a class="btn-home" href="https://www.romanni.com.br/membrosmovimento">
          ENCONTRE UM MEMBRO
        </a>
      </div>
      <div class="col-sm coluna-preencher">
        <a class="btn-home" href="https://www.romanni.com.br/movimentotransformacional">
          SE TORNE UM MEMBRO
        </a>
      </div>
      <div class="col-sm coluna-preencher">
        <a class="btn-home" href="https://movimento.transformacional.com.br/">
          FAZER LOGIN
        </a>
      </div>
    </div>
  </div>
</div>
<div class="section-home py-5">
  <div class="container-center">
    <h2 class="title-home">SBTN</h2>
    <div>
      <p>
        A SBTN tem o propósito de conectar profissionais e instituições de excelência nas áreas de desenvolvimento
        humano, neurociência e terapia.
      </p>
    </div>
  </div>
</div>
<div class="section-home py-5 background-exagono">
  <div class="container-center">
    <h3 class="title-home">O QUE ENCONTRO NA SBTN?</h3>
    <div class="row">
      <div class="col-sm coluna-centralizar">
        <h4>Profissionais de Qualidade</h4>
        <div class="circle-background">
          <img
            src="https://uploads-ssl.webflow.com/5e2088af647de0b43c9ff21d/5e6fb9688a57c236e33e6993_020-presentation.svg"
            class="img-home" alt="">
        </div>
      </div>
      <div class="col-sm coluna-centralizar">
        <h4>Excelência no Desenvolvimento Humano</h4>
        <div class="circle-background">
          <img
            src="https://uploads-ssl.webflow.com/5e2088af647de0b43c9ff21d/5e6fb96b0ad9f72470bd5fd2_035-graduation.svg"
            class="img-home" alt="">
        </div>
      </div>
      <div class="col-sm coluna-centralizar">
        <h4>Evolução Permanente</h4>
        <div class="circle-background">
          <img src="https://uploads-ssl.webflow.com/5e2088af647de0b43c9ff21d/5e6fb96ea58d43be8b7ca6ec_049-success.svg"
            class="img-home" alt="">
        </div>
      </div>
      <div class="col-sm coluna-centralizar">
        <h4>Reconhecimento Nacional</h4>
        <div class="circle-background">
          <img
            src="https://uploads-ssl.webflow.com/5e2088af647de0b43c9ff21d/5e6fb9667fdef40a207fd0ec_005-job-search.svg"
            class="img-home" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="section-home py-5">
  <div class="container-center">
    <div class="row">
      <div class="col-sm">
        <img style="width:280px;" src="https://uploads-ssl.webflow.com/5e2088af647de0b43c9ff21d/5f902cdd8b72cf4b43a50172_sbtn2-p-500.png" alt="">
      </div>
      <div class="col-sm" href="#">
        <img style="width: 75px"
        src="https://uploads-ssl.webflow.com/5e2088af647de0b43c9ff21d/5e30987040e54119e7a4d662_fale-conosco-icone-png-6.png"
        class="img-home" alt="">
        <div class="h4">Fale conosco</div>
        <div>(34) 8408-2180</div>
      </div>
      <div class="col-sm my-auto">
        <a [routerLink]="['/personamap']">Acessar Mapa da Persona</a><br>
        <a [routerLink]="['/businessmodel']">Acessar Modelo de Negócios</a><br>
        <a [routerLink]="['/admatividades']">Acessar Ferramenta Importante Urgente</a><br>
        <a [routerLink]="['/admclientes']">administrar clientes</a>
      </div>
    </div>
  </div>
</footer>