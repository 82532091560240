import { Component,  OnInit } from '@angular/core';

@Component({
  selector: 'busyness-model',
  templateUrl: './busyness-model.component.html',
  styleUrls: ['./busyness-model.component.css'],
  animations: [  ]
})
export class BusynessModelComponent implements OnInit {

  constructor() { 
  }

  ngOnInit(): void {
  }

}
