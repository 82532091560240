<div class="container py-md-4" style="color: #fff;">
    <div class="row">
        <div class="col-md-5 pt-1 pt-md-2" style="background-color: #719639;">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center">COMO?</h3>
                </div>
                <div class="col-md-6 py-2 px-4 py-md-1" style="background-color: #7da63c;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3 col-md-12">
                            <img class="busynessIcon" [src]="getIcon(0)" alt="">
                        </div>
                        <div class="col-9 col-md-12">
                            <h4>{{ getTitulo(0) }}</h4>
                            <div>{{ getDescricao(0) }}</div>
                            <ul *ngIf="getSubItens(0).length > 0">
                                <li *ngFor="let obj of getSubItens(0)"> {{ obj }}</li>
                            </ul>
                            <button type="button"
                                class="mb-md-3 mt-2 float-md-end btn btn-outline-light" (click)="btnAction(0)">Adicionar</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 py-2 px-4 py-md-1" style="background-color: #88b443;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3 col-md-12">
                            <img class="busynessIcon" [src]="getIcon(1)" alt="">
                        </div>
                        <div class="col-9 col-md-12">
                            <h4>{{ getTitulo(1) }}</h4>
                            <div>{{ getDescricao(1) }}</div>
                            <ul *ngIf="getSubItens(1).length > 0">
                                <li *ngFor="let obj of getSubItens(1)"> {{ obj }}</li>
                            </ul>
                            <button type="button"
                                class="mb-md-3 mt-2 float-md-end btn btn-outline-light" (click)="btnAction(1)">Adicionar</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 py-2 px-4 py-md-3">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3">
                            <img class="busynessIcon" [src]="getIcon(2)" alt="">
                        </div>
                        <div class="col-9">
                            <h4>{{ getTitulo(2) }}</h4>
                            <div>{{ getDescricao(2) }}</div>
                            <ul *ngIf="getSubItens(2).length > 0">
                                <li *ngFor="let obj of getSubItens(2)"> {{ obj }}</li>
                            </ul>
                            <button type="button"
                                class="mb-md-3 mt-2 float-md-end btn btn-outline-light" (click)="btnAction(2)">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 pt-1 pt-md-2" style="background-color: #92342d">
            <div class="row h-100">
                <div class="col-12">
                    <h3 class="text-center">O QUE?</h3>
                </div>
                <div class="py-2 px-3 h-100" style="background-color: #af3d35;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3 col-md-12">
                            <img class="busynessIcon" [src]="getIcon(3)" alt="">
                        </div>
                        <div class="col-9 col-md-12">
                            <h4>{{ getTitulo(3) }}</h4>
                            <div>{{ getDescricao(3) }}</div>
                            <ul *ngIf="getSubItens(3).length > 0">
                                <li *ngFor="let obj of getSubItens(3)"> {{ obj }}</li>
                            </ul>
                            <button type="button" class="mb-md-3 mt-2 btn btn-outline-light" (click)="btnAction(3)">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 pt-1 pt-md-2" style="background-color: #0772a6">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center">PARA QUEM?</h3>
                </div>
                <div class="col-md-6 py-2 px-4 py-md-1" style="background-color: #0491d8;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3 col-md-12">
                            <img class="busynessIcon" [src]="getIcon(4)" alt="">
                        </div>
                        <div class="col-9 col-md-12">
                            <h4>{{ getTitulo(4) }}</h4>
                            <div>{{ getDescricao(4) }}</div>
                            <ul *ngIf="getSubItens(4).length > 0">
                                <li *ngFor="let obj of getSubItens(4)"> {{ obj }}</li>
                            </ul>
                            <button type="button" class="mb-md-3 mt-2 btn btn-outline-light" (click)="btnAction(4)">Adicionar</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 py-2 px-4 py-md-1" style="background-color: #03a2dc;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3 col-md-12">
                            <img class="busynessIcon" [src]="getIcon(5)" alt="">
                        </div>
                        <div class="col-9 col-md-12">
                            <h4>{{ getTitulo(5) }}</h4>
                            <div>{{ getDescricao(5) }}</div>
                            <ul *ngIf="getSubItens(5).length > 0">
                                <li *ngFor="let obj of getSubItens(5)"> {{ obj }}</li>
                            </ul>
                            <button type="button" class="mb-md-3 mt-2 btn btn-outline-light" (click)="btnAction(5)">Adicionar</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 py-2 px-4 py-md-3">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3">
                            <img class="busynessIcon" [src]="getIcon(6)" alt="">
                        </div>
                        <div class="col-9">
                            <h4>{{ getTitulo(6) }}</h4>
                            <div>{{ getDescricao(6) }}</div>
                            <ul *ngIf="getSubItens(6).length > 0">
                                <li *ngFor="let obj of getSubItens(6)"> {{ obj }}</li>
                            </ul>
                            <button type="button" class="mb-md-3 mt-2 btn btn-outline-light" (click)="btnAction(6)">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pt-1 pt-md-2" style="background-color: #a16b24">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center">QUANTO?</h3>
                </div>
                <div class="col-md-6 py-2 px-4 py-md-4" style="background-color: #fd9f2e;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3">
                            <img class="busynessIcon" [src]="getIcon(7)" alt="">
                        </div>
                        <div class="col-9">
                            <h4>{{ getTitulo(7) }}</h4>
                            <div>{{ getDescricao(7) }}</div>
                            <ul *ngIf="getSubItens(7).length > 0">
                                <li *ngFor="let obj of getSubItens(7)"> {{ obj }}</li>
                            </ul>
                            <button type="button" class="mb-md-3 mt-2 btn btn-outline-light" (click)="btnAction(7)">Adicionar</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 py-2 px-4 py-md-4" style="background-color: #d47800;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-3">
                            <img class="busynessIcon" [src]="getIcon(8)" alt="">
                        </div>
                        <div class="col-9">
                            <h4>{{ getTitulo(8) }}</h4>
                            <div>{{ getDescricao(8) }}</div>
                            <ul *ngIf="getSubItens(8).length > 0">
                                <li *ngFor="let obj of getSubItens(8)"> {{ obj }}</li>
                            </ul>
                            <button type="button" class="mb-md-3 mt-2 btn btn-outline-light" (click)="btnAction(8)">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2 py-2">
            <button class="btn btn-primary float-end" (click)="imprimir()" >Imprimir Resultado</button>
        </div>
    </div>
</div>